<div
  (click)="isDropdownShown = true"
  #itemsFilterTrigger="cdkOverlayOrigin"
  cdkOverlayOrigin
  class="font-semibold text-sm text-color_primary gap-2 flex h-full items-center w-fit cursor-pointer"
>
  <span>Item Tracked</span>
  <span
    [ngClass]="{ 'rotate-180': isDropdownShown }"
    class="duration-300 ml-2 icon-rounded-triangle text-color_secondary text-size_m"
  ></span>

  <app-overlay-general
    (close)="closeDropdown()"
    [(show)]="isDropdownShown"
    [trigger]="itemsFilterTrigger"
    [overlayPositions]="overlayPositions"
  >
    <div class="rounded-md dropdown-box-shadow bg-white p-5 gap-3.5 flex flex-col">
      <div class="flex gap-2.5 cursor-pointer" *ngFor="let option of dropdownOptions; let index = index">
        <p-checkbox
          (ngModelChange)="onOptionChange()"
          [(ngModel)]="selectedOptions[option.value]"
          [binary]="true"
          [id]="'option_' + index"
          [name]="'option_' + index"
          [value]="option.value"
        ></p-checkbox>
        <div (click)="selectedOptions[option.value] = !selectedOptions[option.value]; onOptionChange()">
          {{ option.name }}
        </div>
      </div>
    </div>
  </app-overlay-general>
</div>
