import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { OverlayGeneralComponent } from '../overlays/overlay-general/overlay-general.component';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';

@Component({
  selector: 'app-checkbox-dropdown',
  standalone: true,
  imports: [CdkOverlayOrigin, CheckboxModule, CommonModule, OverlayGeneralComponent, FormsModule],
  templateUrl: './checkbox-dropdown.component.html',
  styleUrl: './checkbox-dropdown.component.scss',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CheckboxDropdownComponent {
  @Output() selectedOptionsChange = new EventEmitter<{ [key: string]: boolean }>();
  @Input() overlayPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -5,
      offsetY: 5,
    },
  ];

  @Input() selectedOptions: { [key: string]: boolean } = {};

  @Input() dropdownOptions: { name: string; value: string }[] = [
    {
      name: 'In Progress',
      value: 'in_progress',
    },
    {
      name: 'Overdue',
      value: 'overdue',
    },
    {
      name: 'Completed',
      value: 'completed',
    },
  ];

  isDropdownShown = false;

  closeDropdown() {
    this.isDropdownShown = false;
  }

  onOptionChange() {
    setTimeout(() => {
      this.selectedOptionsChange.emit(this.selectedOptions);
    }, 0);
  }
}
